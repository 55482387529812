<template lang="pug">
    v-form(
        ref="form"
        v-model="isValid"
        class="w-full py-4"
        @submit.prevent
    )
        .text-sm.text-black(
            class="lg:text-base lg:col-start-2 lg:col-span-2 col-span-4 text-center pb-6"
            v-html="label"
        )
        .text-center
            v-btn-toggle(
                v-model="score"
                class="nps-scale"
                group
                :rules="[required]"
                :tile="tiled"
            )
                v-btn(
                    v-for="(n, i) in buttonLength"
                    :key="`${i}`"
                    :value="`${i}`"
                ) {{ i }}

        .max-w-440.m-auto.text-sm.flex.justify-between(v-if="npsMinMaxText.length")
            div(v-for="npsText in npsMinMaxText")
                | {{ npsText }}

        .mt-4(
            v-if="showAlert"
            class="col-span-4 text-center text-sm text-red"
        )

            fa-icon(icon="exclamation-triangle" class="mr-2 text-red")
            | {{ message }}
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {SharedMethods} from '@/components/shared/mixins/sharedMixins'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {FormBuilderMethods, FormRules} from '@/components/shared/mixins/formMixins'
import {FormMixins} from '@/components/shared/mixins/formSave'

export default {
    name: 'NetPromoterButtonGroup',
    mixins: [SharedMethods, MessageDialog, FormBuilderMethods, FormMixins, FormRules],
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        tenantName: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        tiled: {
            type: Boolean,
            default: true
        },
        npsMinMaxText: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit, root }) {
        const buttonLength = ref(11)
        const score = ref(props.value)
        const form = ref(null)
        const isValid = ref(true)
        const message = ref('')
        const alertType = ref('error')
        const showAlert = ref(false)
        const isMobile = root.$device.mobile

        const validate = () => {
            isValid.value = !!score.value
            if (!isValid.value) {
                message.value = 'Please select a score'
                showAlert.value = true
            } else {
                showAlert.value = false
            }

            return isValid.value
        }

        watch(() => score.value, (newValue) => {
            validate()
            emit('input', newValue)
            emit('value-changed')
        })

        return {
            buttonLength,
            score,
            form,
            isValid,
            message,
            showAlert,
            alertType,
            isMobile,
            validate
        }
    }
}
</script>
