import gql from 'graphql-tag'


const UPDATE_FORM_STATUS = gql`mutation updateFormStatus(
    $form_answer_id: uuid!,
    $status: String!
) {
    updateFormStatus: update_form_builder_hasura_form_answer_by_pk(
        pk_columns: {
            form_answer_id: $form_answer_id
        }, _set: {status: $status}
    ) {
        status
        form_answer_id
    }
}`

const SAVE_SURVEY_ANSWERS = gql`
mutation saveSurveyAnswers($answer: AnswerInput!) {
    saveSurveyAnswers(answer: $answer) {
        code
        message
        answer
    }
}
`

export {
    UPDATE_FORM_STATUS,
    SAVE_SURVEY_ANSWERS
}
