<template lang="pug">
    v-form(
        v-model="isValid"
        ref="formRef"
        lazy-validation
        @submit.prevent="stopSubmit"
    )
        .m-auto
            //.max-w-1250.bg-white-pure.l-box-shadow.rounded.py-8.px-10.text-left
            .m-auto.max-w-1250.bg-white-pure.l-box-shadow.rounded.py-8.px-10.text-left
                h3.text-center.py-4 How do you think we went?

                NetPromoterButtonGroup(
                    v-if="isSingleNps"
                    ref="netPromoterForm"
                    v-model="form.nps1"
                    :tenant-name="tenantName"
                    :label="labelText"
                    :config="config"
                    :show-text="true"
                    :display-as-row="true"
                )
                NetPromoterScore3Questions(
                    v-else
                    ref="netPromoterForm"
                    v-model="form"
                    :tenant-name="tenantName"
                )

                .grid.justify-items-center.mt-8
                    v-btn(
                        depressed
                        class="success w-130"
                        :class="{'disabled pointer-events-none': loading}"
                        @click="goToNextForm"
                    )
                        fa-icon(v-if="loading" icon="spinner" spin class="mr-2 text-white-pure")
                        | {{ buttonText }}
                        fa-icon(v-if="!loading" icon="chevron-right" class="ml-2 text-white-pure")
</template>

<script>
import {SharedMethods} from '@/components/shared/mixins/sharedMixins'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {FormBuilderMethods, FormRules} from '@/components/shared/mixins/formMixins'
import {FormMixins} from '@/components/shared/mixins/formSave'
import {onMounted, ref, computed} from '@vue/composition-api'
import camelcaseKeys from 'camelcase-keys'
import {GET_TENANT_BY_SHORT_NAME} from '@/graphql/queries/tenantQueries.gql'
import {useLazyQuery, useMutation} from '@vue/apollo-composable'
import {redirectToThankYou} from '@/components/shared/composition-mixins/compositionFormSave'
import snakecaseKeys from 'snakecase-keys'
import {GET_FORM_SET_BY_SHORT_NAME} from '@/graphql/queries/formAnsweFunctions.gql'
import {SAVE_SURVEY_ANSWERS} from '@/graphql/mutations/formFunctions.gql'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import ButtonGroup from '@/components/basic/ButtonGroup'
import NetPromoterButtonGroup from '@/components/basic/NetPromoterButtonGroup'
import {GET_FORM_SET_BY_ID} from '@/graphql/queries/formSetQueries.gql'
import NetPromoterScore3Questions from '@/components/forms/net-promoter-score/partials/NetPromoterScore3Questions'
import FaceButtonGroup from '@/components/basic/FaceButtonGroup'

const { useActions } = createNamespacedHelpers('')

export default {
    name: 'NetPromoterScore',
    components: {NetPromoterButtonGroup, ButtonGroup, NetPromoterScore3Questions, FaceButtonGroup},
    mixins: [SharedMethods, MessageDialog, FormBuilderMethods, FormMixins, FormRules],
    props: {
        formProps: {
            type: Object,
            default: () => {}
        },
        formTitle: {
            type: String,
            default: ''
        },
    },
    setup(props, { root, emit }) {
        const route = root.$route
        const router = root.$router
        const query = route?.query
        const { shortName, kiosk: isKiosk, formSetId, routeType } = route?.params
        const { setFormTitle, setPreviousRoute } = useActions(['setFormTitle', 'setPreviousRoute'])
        const tenantIdOrCompare = ref([])
        const answer = ref(null)
        const formRef = ref({})
        const form = ref({
            nps1: null,
            ...(props?.formProps || {}),
        })
        const isValid = ref(true),
            loading = ref(true),
            disabled = ref(true),
            saving = ref(false),
            defaultButtonText = ref('Next'),
            tenantName = ref(''),
            showAlert = ref(false),
            type = ref(''),
            alertMessage = ref(''),
            tenantId = ref(''),
            formSetQueryParams = ref({}),
            answerToSave = ref({}),
            formSet = ref(null),
            netPromoterForm = ref(null),
            config = ref({
                "subComponents": [
                    {
                        "text": "",
                        "class": "",
                        "value": 1
                    },
                    {
                        "text": "",
                        "class": "",
                        "value": 2
                    },
                    {
                        "text": "",
                        "class": "",
                        "value": 3
                    },
                    {
                        "text": "",
                        "class": "",
                        "value": 4
                    },
                    {
                        "text": "",
                        "class": "",
                        "value": 5
                    }
                ]
            })

        const {
            onResult: getTenantOnResult,
            onError: getTenantOnError,
            load: loadTenant
        } = useLazyQuery(GET_TENANT_BY_SHORT_NAME, { shortName })

        const {
            onResult: formSetOnResult,
            onError: formSetOnError,
            load: formSetLoad,
        } = useLazyQuery(
            GET_FORM_SET_BY_SHORT_NAME, formSetQueryParams
        )

        const {
            onResult: getFormSetOnResult,
            onError: getFormSetOnError,
            load: getFormSetLoad
        } = useLazyQuery(
            GET_FORM_SET_BY_ID, {
                formSetId,
                tenantIdOrCompare
            }
        )

        const {
            onError: formSaveOnError,
            mutate: formSaveMutate,
            onDone: formSaveOnDone
        } = useMutation(SAVE_SURVEY_ANSWERS,
            () => {
                return {
                    variables: {
                        answer: answerToSave.value
                    }
                }
            }
        )

        //#region functions
        const validate = () => {
            // todo: replace with the following validate stuff
            //this.isValid = this.$refs.form?.validate() && this.$refs.questionTree?.validate()
            isValid.value = formRef.value.validate() && netPromoterForm.value.validate()
            return isValid.value
        }

        const clearValidate = () => {
            isValid.value = true
            //this.$refs.form.reset()
            return isValid.value
        }

        const getButtonText = (action) => {
            return saving.value ? 'Redirecting...' : action.buttonText || defaultButtonText.value
        }

        const goToNextForm = () => {
            if (validate()) {
                //need to save the form
                disabled.value = true
                saving.value = true
                loading.value = true
                // first we save the form to the database

                answerToSave.value = {
                    formAnswerId: '',
                    tenantId: tenantId.value,
                    formSetId: formSet.value.formSetId,
                    answer: [snakecaseKeys(form.value, {deep: true})],
                    formTypeId: formSet.value.formTypeId,
                    surveyType: formSet.value.surveyType,
                    userInfo: {
                        userAgent: navigator?.userAgent,
                        platform: navigator?.userAgent?.platform
                    }
                }
                formSaveMutate()
            }
        }
        //#endregion

        //#region Get Tenant
        getTenantOnResult(async ({ data }) => {
            const tenant = camelcaseKeys(data?.tenant, {deep:true})
            if (tenant?.length > 0) {
                tenantName.value = tenant[0]?.name
                tenantId.value = tenant[0]?.tenantId
                formSetQueryParams.value = {
                    ...formSetQueryParams.value,
                    parentTenantId: tenant[0]?.parentTenantId,
                    shortName: shortName
                }
                await setFormTitle(`Thank you for helping us improve ${tenantName.value}`)
                formSetLoad()
                tenantIdOrCompare.value.push({
                    tenant_id: {_eq: tenantId.value}
                })
                if (tenant[0]?.parentTenantId) {
                    tenantIdOrCompare.value.push({
                        tenant_id: {_eq: tenant[0]?.parentTenantId}
                    })
                }
            }
        })

        getTenantOnError((error) => {
            console.log('error', error)
            showAlert.value = true
            type.value = 'error'
            alertMessage.value = 'Could not load tenant, please try reloading the page.'
        })
        //#endregion

        formSetOnResult(({ data }) => {
            if (!data?.formSet.length) {
                formSet.value = {}
                // need to show an error or somethjing
                return
            }
            formSet.value = camelcaseKeys(data?.formSet[0])
        })

        formSetOnError((err) => {
            console.log('error happened', err)
        })

        //#region Form Save
        formSaveOnDone(({ data: { saveSurveyAnswers: { answer } } }) => {
            const formAnswer = camelcaseKeys(answer, {deep: true})
            // for now we just redirect to thankYou
            setTimeout(async () => {
                setPreviousRoute(route.name)
                await redirectToThankYou({
                    // title: formTitle.value,
                    title: null,
                    tenantId: tenantId.value,
                    shortName: shortName,
                    formAnswerId: formAnswer.formAnswerId,
                    formSetId: formSet.formSetId,
                    hasPhoneOrEmail: false,
                    showPhoneAndEmail: true,
                    fullSurvey: false,
                    isKiosk,
                    routeType,
                    emit,
                    router
                })
                disabled.value = false
                saving.value = false
                loading.value = false
            }, 2000)
        })

        formSaveOnError((err) => {
            console.log('error happened while saving the answers', err)
            resetLoading()
        })
        //#endregion

        //#region Get Form Set By Id
        // getFormSetOnResult(({ data }) => {
        getFormSetOnResult(() => {
            // console.log('data loaded', data)
            resetLoading()
        })

        getFormSetOnError((err) => {
            console.log('err from load', err)
        })
        //#endregion

        //#region functions
        const resetLoading = () => {
            isValid.value = false
            loading.value = false
            disabled.value = false
        }

        const stopSubmit = (event) => {
            event.preventDefault()
        }
        //#endregion

        //#region lifecycle hooks
        onMounted(async () => {
            isValid.value = false
            loading.value = false
            disabled.value = false

            answer.value = query?.answer
        })
        //#endregion

        //#region Computed props
        const buttonText = computed(() => {
            return disabled.value ? 'Please wait...' : loading.value ? 'Loading...' : 'Next'
        })

        // const labelText = computed(() => `
        //     On a scale of 1-10, rate how likely you would recommend <b>${tenantName.value}</b> to a friend, family member or colleague.
        // `)
        const labelText = computed(() => `
            On a scale of 1-5, rate how likely you would recommend <b>${tenantName.value}</b> to a friend, family member or colleague.
        `)

        const isSingleNps = computed(() => {
            return routeType === 'nps'
        })
        //#endregion

        //#region run loads
        loadTenant()
        if (formSetId) getFormSetLoad()
        //#endregion

        return {
            form,
            formRef,
            isValid,
            disabled,
            loading,
            defaultButtonText,
            buttonText,
            answer,
            tenantName,
            labelText,
            formSetId,
            netPromoterForm,
            isSingleNps,
            config,
            validate,
            clearValidate,
            getButtonText,
            goToNextForm,
            stopSubmit
        }
    }
}
</script>
